import { Validators } from '@angular/forms';

export const CONSTANTS = {
  DAY_OF_WEEK: [
    {name: '日曜日', key: '0', value: 'sunday'},
    {name: '月曜日', key: '1', value: 'monday'},
    {name: '火曜日', key: '2', value: 'tuesday'},
    {name: '水曜日', key: '3', value: 'wednesday'},
    {name: '木曜日', key: '4', value: 'thursday'},
    {name: '金曜日', key: '5', value: 'friday'},
    {name: '土曜日', key: '6', value: 'saturday'},
  ],
  CORPORATE_CASE: [
    {value: 1, name: '社会福祉法人', abbr: '(福)'},
    {value: 2, name: '株式会社', abbr: '(株)'},
    {value: 3, name: '医療法人', abbr: '(医)'},
    {value: 4, name: '医療法人社団', abbr: '(医)'},
    {value: 5, name: '宗教法人', abbr: '(宗)'},
    {value: 6, name: '特定非営利活動法人', abbr: '(特非)'},
    {value: 7, name: '販売店', abbr: '(販)'},
  ],
  CONTRACT_STATUS: [
    {name: 'ケア記録利用中', key: 'care', value: 5},
    {name: 'トライアル中', key: 'trial', value: 1},
    {name: '実証実験中', key: 'demo', value: 6},
    {name: 'DFree契約中', key: 'contracting', value: 2},
    {name: '契約期間終了', key: 'withdraw', value: 7},
    {name: '社内テスト', key: 'test', value: 3},
    {name: '終了', key: 'deleted', value: 4},
    {name: 'すべて', key: 'all', value: ''}
  ],
  CONTRACT_STATUS_CREATE: [
    {name: 'ケア記録利用中', key: 'care', value: 5},
    {name: 'トライアル中', key: 'trial', value: 1},
    {name: '実証実験中', key: 'demo', value: 6},
    {name: 'DFree契約中', key: 'contracting', value: 2},
    {name: '契約期間終了', key: 'withdraw', value: 7},
    {name: '社内テスト', key: 'test', value: 3},
    {name: '終了', key: 'deleted', value: 4}
  ],
  MEMBERSHIP_STATUS: [
    {name: '無料', value: 'free'},
    {name: '有料', value: 'paid'},
  ],
  NURSING_HOME_CASE: [
    {value: 1, name: '介護老人福祉施設（特別養護老人ホーム）'},
    {value: 2, name: '介護老人保健施設（老人保健施設）'},
    {value: 3, name: '介護療養型医療施設（介護療養病床）'},
    {value: 4, name: 'サービス付き高齢者向け住宅（サ高住）'},
    {value: 6, name: '介護付き有料老人ホーム'},
    {value: 7, name: '養護老人ホーム'},
    {value: 8, name: '軽費老人ホーム'},
    {value: 9, name: '共同生活援助（グループホーム）'},
    {value: 10, name: '障がい者支援施設'},
    {value: 11, name: '介護医療院'},
    {value: 12, name: '病院'},
    {value: 13, name: '小規模多機能型居宅介護'},
    {value: 14, name: '住宅型有料老人ホーム'},
    {value: 15, name: '認知症対応型共同生活介護（グループホーム）'},
    {value: 16, name: '看護小規模多機能型居宅介護'},
    {value: 17, name: '地域密着型特別養護老人ホーム'},
    {value: 18, name: '地域密着型通所介護'},
    {value: 19, name: '通所介護（デイサービス）'},
    {value: 20, name: '短期入所生活介護（ショートステイ）'},
    {value: 21, name: 'パーキンソン病専門ホーム'},
    {value: 22, name: '特別支援学校'},
    {value: 23, name: '大学'},
  ],
  ROLE: {
    ROOT: {value: 'root', name: 'スーパーユーザー(root)'},
    ADMIN: {value: 'admin', name: '管理者(admin)'},
    MEMBER: {value: 'member', name: 'メンバー(member)'}
  },
  SEX: [
    {label: '女性', value: 'female', csvValue: 2},
    {label: '男性', value: 'male', csvValue: 1},
    {label: '未設定', value: null, csvValue: null},
  ],
  PURPOSE: [
    {name: 'トイレ誘導', value: 'toilet'},
    {name: 'おむつ交換', value: 'diaper'},
    {name: 'モニタリング', value: 'monitoring'},
  ],
  IS_DFREE_ACTIVE: [
    {label: '通信切れ', value: 0},
    {label: '通信中', value: 1}
  ],
  DFREE_STATUS: [
    {name: '稼働中', value: 'working'},
    {name: '施設送付済み', value: 'attached'},
    {name: 'Personal利用中', value: 'personal'},
    {name: 'HOME CARE利用中', value: 'homecare'},
    {name: '送付準備完了', value: 'readiness'},
    {name: '掃除中', value: 'cleaning'},
    {name: '掃除待ち', value: 'waiting'},
    {name: '故障中', value: 'breakdown'},
    {name: '開発利用中', value: 'development'},
  ],
  DFREE_DEVICE_TYPE: [
    {name: 'U2', value: 'u2'},
    {name: 'U2P', value: 'u2p'},
    {name: 'U3', value: 'u3'},
    {name: '不明', value: 'unknown'},
  ],
  B_DEVICE_STATUS: [
    {name: '在庫', value: 'stock'},
    {name: '開発利用中', value: 'develop'},
    {name: '返却', value: 'return'},
  ],
  DIAPER_SHAPE: [
    {name: 'おむつ', value: 'outer'},
    {name: 'パッド', value: 'inner'},
    {name: '消耗品', value: 'expendable'},
    {name: 'その他・不明', value: 'unknown'},
  ],
  DIAPER_FORM: [
    {name: '尿とりシート', value: 'seat'},
    {name: '尿とりパッド', value: 'pad'},
    {name: 'パンツ式', value: 'pants'},
    {name: 'テープ式', value: 'tape'},
    {name: '消耗品・その他・不明', value: 'unknown'},
  ],
  DIAPER_SIZE: [
    {name: 'SS', value: 'ss'},
    {name: 'S', value: 's'},
    {name: 'M', value: 'm'},
    {name: 'L', value: 'l'},
    {name: 'LL', value: 'll'},
    {name: 'XL', value: 'xl'},
    {name: 'その他・不明', value: 'unknown'},
  ],
 LAXATIVES_UNIT : [
    {name: '錠', value: 'tablet'},
    {name: '滴', value: 'drop'},
    {name: 'ml', value: 'ml'},
    {name: '包', value: 'pack'},
    {name: '個', value: 'piece'},
    {name: 'その他・不明', value: 'unknown'},
  ],
 LAXATIVES_TYPE : [
    {name: '非刺激性下剤', value: 'nonStimulant'},
    {name: '刺激性下剤', value: 'stimulant'},
    {name: '浣腸', value: 'enema'},
    {name: '坐薬', value: 'suppository'},
  ],
 ENABLE_ORDER : [
    {name: '可能', value: true},
    {name: '不可', value: false},
  ],
 IS_FLAT_RATE : [
    {name: '定額', value: true},
    {name: '非定額', value: false},
  ],
 ORDER_STATUS : [
    {name: 'TW受付', value: 'recept'},
    {name: '一時保存', value: 'temporarilySaved'},
    {name: 'TW受付済み', value: 'accepted'},
    {name: 'TW発注', value: 'order'},
    {name: 'メーカーへのメール送信済み', value: 'mailSent'},
    {name: '発送', value: 'shipping'},
    {name: '配達完了', value: 'complete'},
    {name: '発注取消', value: 'cancel'},
    {name: 'CSV取り込み', value: 'csvImport'},
  ],
 QUICK_ORDER : [
    {name: '定期', value: 'normal'},
    {name: 'お急ぎ(無料)', value: 'freeAsap'},
    {name: 'お急ぎ(有料)', value: 'asap'},
  ],
 PLAN_STATUS: [
    {name: '契約中', value: 'underContract'},
    {name: '一時停止', value: 'suspended'},
    {name: '終了', value: 'withDraw'},
  ],
 OPERATION_STATUS: [
    {name: 'すべて', value: 'all'},
    {name: '未入力', value: 'nodata'},
  ],
 INCLUDE_TAX: [
    {name: '税込', value: true},
    {name: '外税', value: false},
  ],
 NOTIFCATION_SETTINGS: [
    {name: 'ON', value: true},
    {name: 'OFF', value: false},
  ],
 ORDER_LAYER: [
    {name: '施設毎', value: 'nursingHomeLayer'},
    {name: 'ユニット毎', value: 'unitLayer'},
  ],
 NOTIFICATION_TYPE : [
    {name: '接続切れ', value: 'disconnected', color: '#484C75'},
    {name: '接続開始', value: 'connected', color: '#484C75'},
    {name: 'でたかも', value: 'urinated', color: '#FF8FA5'},
    {name: 'そろそろ', value: 'bigBladder', color: '#FFBB00'},
    {name: '電池切れ', value: 'batteryDead', color: '#484C75'},
    {name: '外れた', value: 'dfreeDeviceRemoved', color: '#484C75'},
    {name: '起き上り', value: 'gotUp', color: '#EB3223'},
    {name: '尿リセット', value: 'resetUrine', color: '#00FF00'},
    {name: 'ゼロリセット', value: 'zeroReset', color: '#FF00FF'},
    {name: '上下逆', value: 'upsideDown', color: '#484C75'},
    {name: 'バッテリー異常', value: 'batteryAlert', color: '#484C75'},
    {name: 'ガスでたかも', value: 'pooped', color: '#45e68a'},
    {name: 'ガスでたかも', value: 'gasPooped', color: '#45e68a'},
    {name: 'ガス起き上がり', value: 'gasGotUp', color: '#FF77FF'},
    {name: 'ガス接続切れ', value: 'gasDisconnected', color: '#484C75'},
    {name: 'ガス接続開始', value: 'gasConnected', color: '#484C75'},
    {name: 'ガス電池切れ', value: 'gasBatteryDead', color: '#484C75'},
    {name: 'ガスバッテリー異常', value: 'gasBatteryAlert', color: '#484C75'},
    {name: 'ガスでるかも', value: 'gasPrediction', color: '#d3d3d3'},
  ],
 CARE_RECORD_TYPE : [
    {name: 'ありあり', value: 'all', symbol: 'square', fillColor: '#75F94C', lineColor: '#75F94C'},
    {name: 'ありあり(多)', value: 'all_large', symbol: 'square', fillColor: '#75F94C', lineColor: '#75F94C'},
    {name: 'ありあり(中)', value: 'all_medium', symbol: 'square', fillColor: '#75F94C', lineColor: '#75F94C'},
    {name: 'ありあり(少)', value: 'all_small', symbol: 'square', fillColor: '#75F94C', lineColor: '#75F94C'},
    {name: 'ありなし', value: 'half', symbol: 'square', fillColor: '#72FBFD', lineColor: '#00FFFF'},
    {name: 'なしあり', value: 'mid', symbol: 'square', fillColor: '#CCCCCC', lineColor: '#CCCCCC'},
    {name: 'なしあり(多)', value: 'mid_large', symbol: 'square', fillColor: '#CCCCCC' , lineColor: '#CCCCCC'},
    {name: 'なしあり(中)', value: 'mid_medium', symbol: 'square', fillColor: '#CCCCCC' , lineColor: '#CCCCCC'},
    {name: 'なしあり(少)', value: 'mid_small', symbol: 'square', fillColor: '#CCCCCC', lineColor: '#CCCCCC'},
    {name: 'なしなし', value: 'no', symbol: 'square', fillColor: '#6D6D6D', lineColor: '#6D6D6D'},
  ],
 CARE_RECORD_TOILET_TYPE: [
    {name: 'トイレ', value: 'toilet'},
    {name: 'ポータブルトイレ', value: 'ptoilet'},
    {name: 'ベッド', value: 'bed'},
    {name: '尿瓶', value: 'urineBottle'},
    {name: '導尿', value: 'urethralCat'},
    {name: 'その他', value: 'other'},
  ],
 CARE_RECORD_PAD_TYPE: [
    {name: 'パッド', value: 'pad'},
    {name: 'リハビテーションパンツ', value: 'pants'},
    {name: 'カテーテル', value: 'catheter'},
    {name: '布パンツ', value: 'clothPants'},
    {name: 'その他', value: 'other'},
  ],
 CARE_RECORD_PAD_VOLUME_TYPE: [
    {name: '多', value: 'large'},
    {name: '中', value: 'medium'},
    {name: '少', value: 'small'},
    {name: '無し', value: 'none'},
  ],
 CARE_RECORD_DEF_VOLUME_TYPE: [
    {name: '多', value: 'large'},
    {name: '普', value: 'medium'},
    {name: '少', value: 'small'},
    {name: '付き', value: 'bit'},
    {name: '無', value: 'none'},
  ],
 CARE_RECORD_DEF_HARD_TYPE: [
    {name: 'コロコロ便', value: 'korokoro'},
    {name: '硬い便', value: 'hard'},
    {name: 'やや硬い便', value: 'littleHard'},
    {name: '普通便', value: 'middle'},
    {name: 'やや柔らかい', value: 'soft'},
    {name: '泥状便', value: 'littleSoft'},
    {name: '水様便', value: 'water'},
    {name: '摘便', value: 'disimpaction'},
  ],
 DEVICE_MAS_TYPE : [
    {name: 'PP1', value: '21P1'},
    {name: 'PP2', value: '21P2'},
    {name: 'MP1', value: '2111'},
    {name: 'MP2', value: 'CEBB'},
    {name: 'MP3', value: 'CFCB'},
    {name: 'MP4', value: 'CFDC'},
    {name: 'MP5', value: 'CFED'},
    {name: 'MP6', value: 'CFFD'},
    {name: 'MP7', value: 'CGGE'},
    {name: 'MP8', value: 'CGHF'},
    {name: 'MP9', value: 'CGJG'},
    {name: 'MP10', value: 'CHKG'},
    {name: 'MP11', value: 'CHLG'},
  ],
 POSTURE_Y_AXIS : {
   'u2':
    [
      '0: 立位or計測不能座位',
      '1: 仰臥位',
      '2: うつ伏せ',
      '3: 右側臥位',
      '4: 左側臥位',
      '5: 立位or座位',
      '6: 逆立ち',
    ],
   'u3':
    [
      '0: 立位or計測不能座位',
      '1: 仰臥位',
      '2: うつ伏せ',
      '3: 右側臥位',
      '4: 左側臥位',
      '5: 立位or座位',
      '6: 逆立ち',
    ],
   'unknown':
    [
      '0: 立位or計測不能座位',
      '1: 仰臥位',
      '2: うつ伏せ',
      '3: 右側臥位',
      '4: 左側臥位',
      '5: 立位or座位',
      '6: 逆立ち',
    ],
   'u2p':
    [
      '0: 立位or計測不能座位',
      '1: 立位or座位',
      '2: 逆立ち',
      '3: 仰臥位',
      '4: うつ伏せ',
      '5: 左側臥位',
      '6: 右側臥位',
    ]
 },
 BEN_DEVICE_TYPE : [
    {name: 'ガス', value: 'gas'},
    {name: 'コロン左', value: 'colonl'},
    {name: 'コロン右', value: 'colonr'},
  ],
 ANNOUNCEMENT_LANG : [
    {name: '日本語', value: 'ja'},
    {name: '英語', value: 'en'},
  ],
 LIFE_SETTINGS: [
    {name: 'あり', value: true},
    {name: 'なし', value: false},
  ],
 LIFE_CARE_LEVEL: [
    {name: '非該当', value: '01'},
    {name: '事業対象者', value: '06'},
    {name: '要支援（経過的要介護）', value: '11'},
    {name: '要支援１', value: '12'},
    {name: '要支援２', value: '013'},
    {name: '要介護', value: '21'},
    {name: '要介護２', value: '22'},
    {name: '要介護３', value: '23'},
    {name: '要介護４', value: '24'},
    {name: '要介護５', value: '25'},
  ],
 LIFE_IMPAIRED: [
    {name: '自立', value: 'independence', csvValue: 1},
    {name: 'J1', value: 'indJ1', csvValue: 2},
    {name: 'J2', value: 'indJ2', csvValue: 3},
    {name: 'A1', value: 'indA1', csvValue: 4},
    {name: 'A2', value: 'indA2', csvValue: 5},
    {name: 'B1', value: 'indB1', csvValue: 6},
    {name: 'B2', value: 'indB2', csvValue: 7},
    {name: 'C1', value: 'indC1', csvValue: 8},
    {name: 'C2', value: 'indC2', csvValue: 9},
  ],
 LIFE_DEMENTIA: [
    {name: '自立', value: 'independence', csvValue: 1},
    {name: 'Ⅰ', value: 'ind1', csvValue: 2},
    {name: 'Ⅱa', value: 'ind2a', csvValue: 3},
    {name: 'Ⅱb', value: 'ind2b', csvValue: 4},
    {name: 'Ⅲa', value: 'ind3a', csvValue: 5},
    {name: 'Ⅲb', value: 'ind3b', csvValue: 6},
    {name: 'Ⅳ', value: 'ind4', csvValue: 7},
    {name: 'M', value: 'indm', csvValue: 8},
  ],
 LIFE_CARE_PLANNING_JOB_CATEGORY: [
    {name: '医師', value: '010'},
    {name: '歯科医師', value: '020'},
    {name: '薬剤師', value: '030'},
    {name: '看護師', value: '040'},
    {name: '准看護師', value: '050'},
    {name: '保健師', value: '060'},
    {name: '助産師', value: '070'},
    {name: '理学療法士', value: '080'},
    {name: '作業療法士', value: '090'},
    {name: '言語聴覚士', value: '100'},
    {name: '柔道整復師', value: '110'},
    {name: 'あん摩マッサージ指圧師', value: '120'},
    {name: 'はり師・きゅう師', value: '125'},
    {name: '歯科衛生士', value: '130'},
    {name: '精神保健福祉士', value: '140'},
    {name: '介護支援専門員', value: '150'},
    {name: '社会福祉士', value: '160'},
    {name: '介護福祉士', value: '170'},
    {name: '福祉用具専門相談員', value: '180'},
    {name: '管理栄養士', value: '190'},
    {name: '栄養士', value: '200'},
    {name: 'その他', value: '210'},
    {name: '不明', value: '999'},
  ],
 LIFE_CARE_PLANNING_URINATION: [
    {name: '全介助', value: 'all', csvValue: 0},
    {name: '一部介助', value: 'part', csvValue: 1},
    {name: '見守り等', value: 'monitoring', csvValue: 2},
    {name: '介助されていない', value: 'none', csvValue: 3},
  ],
 LIFE_CARE_PLANNING_USE_DIAPER: [
    {name: 'なし', value: 'none', csvValue: 0},
    {name: 'あり（日中のみ）', value: 'daytime', csvValue: 1},
    {name: 'あり（夜間のみ）', value: 'night', csvValue: 2},
    {name: 'あり（終日）', value: 'all', csvValue: 3},
  ],
 LIFE_CARE_PLANNING_EXCRETION_STATUS: [
    {name: '無し', value: 'none', csvValue: 0},
    {name: '有り', value: 'necessity', csvValue: 1},
  ],
 TENDENCY_SPAN_TYPE : [
  {name: '終日', value: 'all-time'},
  {name: '日中(7時〜19時)', value: 'day-time'},
  {name: '夜間(19時〜7時)', value: 'night-time'},
  {name: '自由設定', value: 'free'},
  ],
 TENDENCY_SPAN_TIME : [
  {name: '0:00', value: '00'},
  {name: '1:00', value: '01'},
  {name: '2:00', value: '02'},
  {name: '3:00', value: '03'},
  {name: '4:00', value: '04'},
  {name: '5:00', value: '05'},
  {name: '6:00', value: '06'},
  {name: '7:00', value: '07'},
  {name: '8:00', value: '08'},
  {name: '9:00', value: '09'},
  {name: '10:00', value: '10'},
  {name: '11:00', value: '11'},
  {name: '12:00', value: '12'},
  {name: '13:00', value: '13'},
  {name: '14:00', value: '14'},
  {name: '15:00', value: '15'},
  {name: '16:00', value: '16'},
  {name: '17:00', value: '17'},
  {name: '18:00', value: '18'},
  {name: '19:00', value: '19'},
  {name: '20:00', value: '20'},
  {name: '21:00', value: '21'},
  {name: '22:00', value: '22'},
  {name: '23:00', value: '23'},
  ],
  // cite:https://holidays-jp.github.io/api/v1/date.json
  JAPANESE_HOLIDAYS: {
    "2019-01-01": "元日",
    "2019-01-14": "成人の日",
    "2019-02-11": "建国記念の日",
    "2019-03-21": "春分の日",
    "2019-04-29": "昭和の日",
    "2019-04-30": "祝日",
    "2019-05-01": "天皇の即位の日",
    "2019-05-02": "祝日",
    "2019-05-03": "憲法記念日",
    "2019-05-04": "みどりの日",
    "2019-05-05": "こどもの日",
    "2019-05-06": "こどもの日 振替休日",
    "2019-07-15": "海の日",
    "2019-08-11": "山の日",
    "2019-08-12": "休日 山の日",
    "2019-09-16": "敬老の日",
    "2019-09-23": "秋分の日",
    "2019-10-14": "スポーツの日",
    "2019-10-22": "即位礼正殿の儀の行われる日",
    "2019-11-03": "文化の日",
    "2019-11-04": "文化の日 振替休日",
    "2019-11-23": "勤労感謝の日",
    "2020-01-01": "元日",
    "2020-01-13": "成人の日",
    "2020-02-11": "建国記念の日",
    "2020-02-23": "天皇誕生日",
    "2020-02-24": "天皇誕生日 振替休日",
    "2020-03-20": "春分の日",
    "2020-04-29": "昭和の日",
    "2020-05-03": "憲法記念日",
    "2020-05-04": "みどりの日",
    "2020-05-05": "こどもの日",
    "2020-05-06": "憲法記念日 振替休日",
    "2020-07-23": "海の日",
    "2020-07-24": "スポーツの日",
    "2020-08-10": "山の日",
    "2020-09-21": "敬老の日",
    "2020-09-22": "秋分の日",
    "2020-11-03": "文化の日",
    "2020-11-23": "勤労感謝の日",
    "2021-01-01": "元日",
    "2021-01-11": "成人の日",
    "2021-02-11": "建国記念の日",
    "2021-02-23": "天皇誕生日",
    "2021-03-20": "春分の日",
    "2021-04-29": "昭和の日",
    "2021-05-03": "憲法記念日",
    "2021-05-04": "みどりの日",
    "2021-05-05": "こどもの日",
    "2021-07-22": "海の日",
    "2021-07-23": "スポーツの日",
    "2021-08-08": "山の日",
    "2021-08-09": "休日 山の日",
    "2021-09-20": "敬老の日",
    "2021-09-23": "秋分の日",
    "2021-11-03": "文化の日",
    "2021-11-23": "勤労感謝の日",
    "2022-01-01": "元日",
    "2022-01-10": "成人の日",
    "2022-02-11": "建国記念の日",
    "2022-02-23": "天皇誕生日",
    "2022-03-21": "春分の日",
    "2022-04-29": "昭和の日",
    "2022-05-03": "憲法記念日",
    "2022-05-04": "みどりの日",
    "2022-05-05": "こどもの日",
    "2022-07-18": "海の日",
    "2022-08-11": "山の日",
    "2022-09-19": "敬老の日",
    "2022-09-23": "秋分の日",
    "2022-10-10": "スポーツの日",
    "2022-11-03": "文化の日",
    "2022-11-23": "勤労感謝の日",
    "2023-01-01": "元日",
    "2023-01-02": "休日 元日",
    "2023-01-09": "成人の日",
    "2023-02-11": "建国記念の日",
    "2023-02-23": "天皇誕生日",
    "2023-03-21": "春分の日",
    "2023-04-29": "昭和の日",
    "2023-05-03": "憲法記念日",
    "2023-05-04": "みどりの日",
    "2023-05-05": "こどもの日",
    "2023-07-17": "海の日",
    "2023-08-11": "山の日",
    "2023-09-18": "敬老の日",
    "2023-09-23": "秋分の日",
    "2023-10-09": "スポーツの日",
    "2023-11-03": "文化の日",
    "2023-11-23": "勤労感謝の日",
    "2024-01-01": "元日",
    "2024-01-08": "成人の日",
    "2024-02-11": "建国記念の日",
    "2024-02-12": "建国記念の日 振替休日",
    "2024-02-23": "天皇誕生日",
    "2024-03-20": "春分の日",
    "2024-04-29": "昭和の日",
    "2024-05-03": "憲法記念日",
    "2024-05-04": "みどりの日",
    "2024-05-05": "こどもの日",
    "2024-05-06": "こどもの日 振替休日",
    "2024-07-15": "海の日",
    "2024-08-11": "山の日",
    "2024-08-12": "休日 山の日",
    "2024-09-16": "敬老の日",
    "2024-09-22": "秋分の日",
    "2024-09-23": "秋分の日 振替休日",
    "2024-10-14": "スポーツの日",
    "2024-11-03": "文化の日",
    "2024-11-04": "文化の日 振替休日",
    "2024-11-23": "勤労感謝の日",
    "2025-01-01": "元日",
    "2025-01-13": "成人の日",
    "2025-02-11": "建国記念の日",
    "2025-02-23": "天皇誕生日",
    "2025-02-24": "天皇誕生日 振替休日",
    "2025-03-20": "春分の日",
    "2025-04-29": "昭和の日",
    "2025-05-03": "憲法記念日",
    "2025-05-04": "みどりの日",
    "2025-05-05": "こどもの日",
    "2025-05-06": "みどりの日 振替休日",
    "2025-07-21": "海の日",
    "2025-08-11": "山の日",
    "2025-09-15": "敬老の日",
    "2025-09-23": "秋分の日",
    "2025-10-13": "スポーツの日",
    "2025-11-03": "文化の日",
    "2025-11-23": "勤労感謝の日",
    "2025-11-24": "勤労感謝の日 振替休日",
    "2026-01-01": "元日",
    "2026-01-12": "成人の日",
    "2026-02-11": "建国記念の日",
    "2026-02-23": "天皇誕生日",
    "2026-03-20": "春分の日",
    "2026-04-29": "昭和の日",
    "2026-05-03": "憲法記念日",
    "2026-05-04": "みどりの日",
    "2026-05-05": "こどもの日",
    "2026-05-06": "憲法記念日 振替休日",
    "2026-07-20": "海の日",
    "2026-08-11": "山の日",
    "2026-09-21": "敬老の日",
    "2026-09-22": "国民の休日",
    "2026-09-23": "秋分の日",
    "2026-10-12": "スポーツの日",
    "2026-11-03": "文化の日",
    "2026-11-23": "勤労感謝の日",
    "2027-01-01": "元日",
    "2027-01-11": "成人の日",
    "2027-02-11": "建国記念の日",
    "2027-02-23": "天皇誕生日",
    "2027-03-21": "春分の日",
    "2027-03-22": "春分の日 振替休日",
    "2027-04-29": "昭和の日",
    "2027-05-03": "憲法記念日",
    "2027-05-04": "みどりの日",
    "2027-05-05": "こどもの日",
    "2027-07-19": "海の日",
    "2027-08-11": "山の日",
    "2027-09-20": "敬老の日",
    "2027-09-23": "秋分の日",
    "2027-10-11": "スポーツの日",
    "2027-11-03": "文化の日",
    "2027-11-23": "勤労感謝の日",
    "2028-01-01": "元日",
    "2028-01-10": "成人の日",
    "2028-02-11": "建国記念の日",
    "2028-02-23": "天皇誕生日",
    "2028-03-20": "春分の日",
    "2028-04-29": "昭和の日",
    "2028-05-03": "憲法記念日",
    "2028-05-04": "みどりの日",
    "2028-05-05": "こどもの日",
    "2028-07-17": "海の日",
    "2028-08-11": "山の日",
    "2028-09-18": "敬老の日",
    "2028-09-22": "秋分の日",
    "2028-10-09": "スポーツの日",
    "2028-11-03": "文化の日",
    "2028-11-23": "勤労感謝の日",
    "2029-01-01": "元日",
    "2029-01-08": "成人の日",
    "2029-02-11": "建国記念の日",
    "2029-02-12": "建国記念の日 振替休日",
    "2029-02-23": "天皇誕生日",
    "2029-03-20": "春分の日",
    "2029-04-29": "昭和の日",
    "2029-04-30": "昭和の日 振替休日",
    "2029-05-03": "憲法記念日",
    "2029-05-04": "みどりの日",
    "2029-05-05": "こどもの日",
    "2029-07-16": "海の日",
    "2029-08-11": "山の日",
    "2029-09-17": "敬老の日",
    "2029-09-23": "秋分の日",
    "2029-09-24": "秋分の日 振替休日",
    "2029-10-08": "スポーツの日",
    "2029-11-03": "文化の日",
    "2029-11-23": "勤労感謝の日"
  },
　VALIDATE_PATTERN: {
    EMAIL: Validators.pattern('[a-zA-Z0-9!$&*.=^`|~#%\'+\\/?_{}-]+@([a-zA-Z0-9_-]+\\.)+[a-zA-Z]{2,8}'),
    TEL: Validators.pattern('^[0-9-]{6,12}$|^[0-9-]{13}$')
  },
  TRIPLE_W_COMPANY_ID: {
    value: 1
  },
  FACILITY_REFERENCE_VALUE: [
    {
      type: 1,
      activeUser: null,
      capacity: null,
      invoice: null,
      nursingHomeId: null,
      nursingHomeName: '',
      occupancyRate: null,
      plan: null,
      yyyyMM: '基準値',
      deliveryPrice: null,
      deliveryPerPerson: 4980,
      numberOfDiaper: 0.91,
      numberOfPad: 4.08,
      absorption: 2917,
    },
    {
      type: 2,
      activeUser: null,
      capacity: null,
      invoice: null,
      nursingHomeId: null,
      nursingHomeName: '',
      occupancyRate: null,
      plan: null,
      yyyyMM: '基準値',
      deliveryPrice: null,
      deliveryPerPerson: 3980,
      numberOfDiaper: 0.90,
      numberOfPad: 3.56,
      absorption: 2385,
    },
    {
      type: 3,
      activeUser: null,
      capacity: null,
      invoice: null,
      nursingHomeId: null,
      nursingHomeName: '',
      occupancyRate: null,
      plan: null,
      yyyyMM: '基準値',
      deliveryPrice: null,
      deliveryPerPerson: 5380,
      numberOfDiaper: 0.95,
      numberOfPad: 3.83,
      absorption: 2883,
    },
  ]
};
